<template>
  <van-swipe :autoplay="3000">
    <van-swipe-item v-for="item in picNameList">
      <el-image :src="require(`@/assets/carousel/${item}`)"/>
    </van-swipe-item>
  </van-swipe>
</template>

<script>

export default {
  name: "MSwiper",
  data() {
    return {
      picNameList: [
        'img_a_main1aa.jpg',
        'img_a_main2aa.jpg',
        'img_a_main3aa.jpg',
      ],
    }
  }
}
</script>

<style scoped>

</style>
