<template>
<div id="access">
  <div class="schedule">
     <div class="name">
      <!-- <div class="name-tenyukai">医療法人社団 天裕会</div> -->
      <div class="name-nozomi">築地薬局</div>
    </div>
    <div class="address">
      <div>〒104-0045</div>
      <div>東京都中央区築地7丁目6‐7 松田ビル 2F</div>
      <br>
      <div>日比谷線「築地駅」1番出口より徒步4分</div>
    </div>
    <div class="tel">
      <div class="tel-number">TEL:</div>
      <div class="tel-number">03-5708-5852</div>
      
    </div>
    <div class="tel">
      <div class="tel-number">FAX:</div>
      <div class="tel-number">03-5708-5853</div>
      
    </div>
    <div class="table">
      <table>
        <tr>
          <td>営業時間</td>
          <td>月</td>
          <td>火</td>
          <td>水</td>
          <td>木</td>
          <td>金</td>
          <td>土</td>
          <td>日</td>
        </tr>
        <tr>
          <td>10:00 ～ 13:30</td>
          <td>◯</td>
          <td>◯</td>
          <td>/</td>
          <td>◯</td>
          <td>◯</td>
          <td>◯</td>
          <td>/</td>
        </tr>
        <tr>
          <td>14:30 ～ 19:00</td>
          <td>◯</td>
          <td>◯</td>
          <td>/</td>
          <td>◯</td>
          <td>◯</td>
          <td>◯</td>
          <td>/</td>
        </tr>
      </table>
    </div>
    <!-- <div class="notice">※ 受付は３０分前までとなります。</div> -->
  </div>

  <div class="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3241.451346247618!2d139.773819!3d35.665887!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889621c1d43c3%3A0x1c2ca4a0d12f95fb!2z44CSMTA0LTAwNDUgVG9reW8sIENodW8gQ2l0eSwgVHN1a2lqaSwgNy1jaMWNbWXiiJI24oiSNyDmnb7nlLDjg5Pjg6s!5e0!3m2!1sen!2sjp!4v1715915234808!5m2!1sen!2sjp" width="100%" height="260" style="border:none;" allowfullscreen="" loading="lazy"></iframe>
  </div>

  <div class="info">
 
    <!-- <div class="btn-online" @click="goTo('https://jashop.rapha99.com/')">
      <img src="../../assets/icon/yj_blue.png" alt="" width="36px" height="36px">
      <div class="btn-text">オンライン薬局</div>
    </div>

   
    <div class="btn-mail"  @click="show = true">
      <img src="../../assets/icon/line.png" alt="" width="36px" height="36px">
      <div class="btn-text1">LINEで友達追加</div>
    </div> -->
  </div>
  <van-overlay :show="show" @click="show = false">
    <div class="wrapper">
      <div class="block" @click.stop>
        <p>スマートフォンでご覧ください</p>
        <img src="../../assets/carousel/ewm.jpg" height="270px" alt="">
        <p>LINEアプリを起動して、</p>
        <p>QRコードを読み取ってください。</p>
      </div>
    </div>
  </van-overlay>
  
</div>
</template>

<script>
export default {
  name: "MAccess",
  data() {
    return {
      show:false
    }
  },
  methods: {
    goTo(url) {
      window.open(url,'_blank')
    }
  }
}
</script>

<style scoped>
#access {
  padding: 30px 20px;
}
/* 病院情報 */
.schedule .name-tenyukai {
  color: #9E9E9E;
  font-size: 10px;
  font-weight: bold;
}
.schedule .name-nozomi {
  color: #3232e9;
  font-size: 16px;
  font-weight: bold;
}
.schedule .address {
  color: #3232e9;
  font-size: 12px;
  margin-top: 10px;
}
.schedule .tel {
  align-items: flex-start;
  display: flex;
  margin-top: 14px;
}
.schedule .tel .tel-number {
  font-size: 18px;
  color: #3232e9;
  font-weight: bold;
  margin-left: 10px;
}
/* 診療時間 */
.schedule .table table {
  color: #3232e9;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
}
.schedule .table table tr:first-child {
  background-color: #fafd3e;
}

.schedule .table table tr{
  background-color: #caeec8;
}
.schedule .table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 8px 12px;
}
.schedule .notice {
  font-size: 12px;
  margin-top: 10px;
}
/* MAP */
.map {
  margin-top: 20px;
}
/* ボタン */
.info {
  margin-top: 20px;
}
.info .btn-online {
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  box-shadow: 0 2px 4px #CCCCCC;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
}
.info .btn-mail {
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #2ac267;
  border-radius: 5px;
  box-shadow: 0 2px 4px #CCCCCC;
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  margin-top: 14px;
}
.info .btn-text {
  color: #62b6e2;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.info .btn-text1{
  color: #2ac267;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}
.info .btn-text .sub-text {
  color: #62b6e2;
  font-size: 12px;
  font-weight: bold;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  /* height: 120px; */
  background-color: #fff;
}
.block p{
  text-align: center;
}
.block img{
  margin-left: 15%;
}
</style>
