<template>
  <div id="header">

    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div class="top">
          <div class="clinic">
            <el-link :underline="false" href="https://hope-medical.jp/#/">
              <div class="clinic-wrapper">
                  <div class="logo">
                    <img src="../assets/logo/hope_36x50@2x.png" alt="Hope" width="36px">
                  </div>
                <div class="name">
                  <el-link :underline="false" href="https://hope-medical.jp/#/">
                    <div class="name-tenyukai">医療法人社団 天裕会</div>
                    <div class="name-nozomi">のぞみクリニック{{ this.clinicName }}</div>
                  </el-link>
                </div>
              </div>
            </el-link>
          </div>
        </div>

        <!--<div class="covid">-->
        <!-- <div class="btn" @click="goHref('http://covid.rapha99.jp/page99')">コロナワクチン接種受付(個人)</div>-->
        <!-- <div class="btn" @click="goHref('http://covid.rapha99.jp')">コロナワクチン接種受付(集団)</div>-->
        <!--</div>-->

        <div class="link">
          <div @mouseover="hoverPcr = true" @mouseout="hoverPcr = false">
            <el-link :underline="false" href="https://www.tecot.go.jp/" target="_blank">
              <span>PCR検査(海外渡航)</span>
            </el-link>
          </div>
          <div @mouseover="hoverPcrCN = true" @mouseout="hoverPcrCN = false">
            <el-link :underline="false" @click="goTo('/pcrcn')">
              <span>PCR検査(中国渡航)</span>
            </el-link>
          </div>
          <div @mouseover="hoverInformation = true" @mouseout="hoverInformation = false">
            <el-link :underline="false" @click="goTo('/information')">
              <span>お知らせ</span>
            </el-link>
          </div>
          <div @mouseover="hoverAbout = true" @mouseout="hoverAbout = false">
            <el-link :underline="false" @click="goTo('/about')">
              <span>当院について</span>
            </el-link>
          </div>
          <div @mouseover="hoverAccess = true" @mouseout="hoverAccess = false">
            <el-link :underline="false" @click="goTo('/access')">
              <span>アクセス</span>
            </el-link>
          </div>
          <div @mouseover="hoverSending = true" @mouseout="hoverSending = false">
            <el-link :underline="false" @click="goTo('/sending')">
              <span>お問い合わせ</span>
            </el-link>
          </div>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {
      hoverPcr: '',
      hoverPcrCN: '',
      hoverInformation: '',
      hoverAbout: '',
      hoverAccess: '',
      hoverQuestion: '',
      hoverSending: '',
    }
  },
  props: {
    clinicName: {
      default: '築地'
    },
    pathName: {
      default: 'dougendou'
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    goHref(path) {
      window.location.href = path;
    }
  }
}
</script>

<style scoped>
/* ロゴ */
.top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.clinic-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.clinic .name {
  margin-left: 14px;
}
.clinic .name-tenyukai {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: bold;
}
.clinic .name-nozomi {
  color: #62B6E2;
  font-size: 24px;
  font-weight: bold;
}
/* メニュー */
.link {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.link /deep/ .el-link span:first-child {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.link a:hover {
  color: #62b6e2;
}
.link .el-link {
  color: #9e9e9e;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
}

</style>
