<template>
   <div class="information">
    <div class="content">
      <!-- <div class="component-title">薬局からのお知らせ</div> -->
    </div>
    <div class="inf-request">
      <div class="request" v-for="item in informationList" >
        <el-link :underline="false">
          <span class="content">{{ item.title }}</span>
        </el-link>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "HomeInformation",
  data() {
    return {
      informationList: [
        // {title:'あなたの街に新しい調剤薬局ができました。'},
        // {title:'親切　丁寧　親身にお医者様から出されたお薬を調剤いたします。新薬はもちろん漢方薬も保険で気軽に出してもらえます'},
        // {title:'さらにオンラインTV電話でドクターと直接話せて診断してもらえてお薬も出してくれる病院もご紹介できます。'},
        // {title:'そのほかいつでも、無料にてお薬の相談、健康相談、漢方薬の相談もさせていただいてます。'},
        // {title:'ぜひお気軽にお立ち寄りください。'},
        {title:'はじめまして'},
        {title:'築地薬局です。'},
        {title:'地域のみなさまに愛され、お役に立てる薬局を目指して、スタッフ一同サービス向上に努めてまいります。'},
        {title:'お引き立ての程'},
        {title:'よろしくお願いいたします。'},
        {title:''},
        {title:'・どちらの医療機関の処方せんも調剤致します'},
        {title:'・無料漢方相談だけでも結構です'},
        {title:'・お薬はご自宅への郵送も可能です(有料)'},
      ]
    }
  },
  created() {
  
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getInfo(id) {
      this.$router.push({
        path: '/information',
        query: {id}
      })
    }
  }
}
</script>

<style scoped>
/*病院からのお知らせ*/
.component-title {
  background-color: #62b6e2;
  color: #FFFFFF;
  font-size: 18px;
  padding: 14px 30px;
  width: 30%;
}
.inf-request {
  /* border: 2px solid #62b6e2; */
  padding: 30px;
}
.inf-request .request {
  border-bottom: 1px dashed #CCCCCC;
  overflow: hidden;  
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*行数*/
  -webkit-box-orient: vertical;
  padding: 14px 0;
}
.inf-request .request a:hover span {
  color: #3232e9;
}
.inf-request .content {
  color: #3232e9;
  font-size: 16px;
  margin-left: 30px;
  /* text-decoration: underline; */
}
.to-inf {
  background-color: #62b6e2;
  border-radius: 5px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 14px;
  text-align: left;
  margin-top: 30px;
  padding: 10px 20px;
}
.to-inf .el-link {
  color: #FFFFFF;
}
.to-inf:hover, .to-inf:hover .el-link {
  background-color: #62b6e2;
  color: #FFFFFF;
  opacity: 0.8;
}
</style>
