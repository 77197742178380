<template>
<div id="online" class="online">
  <div class="info">
    <div class="info-title">営業時間</div>
    <div class="info-content">
      <div>
        <span class="content-title">営業時間</span>
        <span class="content-text">10:00 ~ 13:30</span>
        <span class="content-text">14:30 ~ 19:00</span>
      </div>
      <div>
        <span class="content-title">定休日　</span>
        <span class="content-text">日曜日・水曜日・祝日</span>
      </div>
    </div>
    <div class="info-tel">
      <div class="tel-number">TEL:</div>
      <div class="tel-number">03-5708-5852</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HomeOnline",
  methods: {
    goTo(url) {
      window.open(url,'_blank')
    }
  }
}
</script>

<style scoped>
/* オンライン予約 */
.btn {
  align-items: center;
  background-color: #62B6E2;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  box-shadow:  2px 0 4px #AAAAAA;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  width: 240px;
}
.btn:hover {
  background-color: #40A3D8;
}
.text {
  margin-left: 10px;
}
.text-web {
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}
.text-online {
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  margin-top: 4px;
}
/* 病院情報 */
.info {
  background-color: #ffff99;
  border-radius: 10px;
  opacity: 0.9;
  padding: 30px;
  margin-top: 18px;
  width: 480px;
}
.info .info-title {
  color: #3232e9;
  font-size: 20px;
  font-weight: bold;
}
.info .info-content {
  margin-top: 20px;
}
.info .content-title {
  color: #3232e9;
  font-size: 14px;
  font-weight: bold;
}
.info .content-text {
  color: #3232e9;
  font-size: 14px;
  margin-left: 20px;
}
.info .info-tel {
  align-items: center;
  display: flex;
  margin-top: 24px;
  color: #3232e9;
}
.info .tel-number {
  font-size: 24px;
  font-weight: bold;
  margin-left: 14px;
}
</style>
