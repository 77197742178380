<template>
<div id="info">
  <!-- <div class="info-title">薬局からのお知らせ</div> -->

  <div class="info-list" >
    <div class="content">築地薬局</div>
    <br>
    <div class="content">地域のみなさまに愛され、お役に立てる薬局を目指して、スタッフ一同サービス向上に努めてまいります。</div>
    <div class="content">お引き立ての程</div>
    <div class="content">よろしくお願いいたします。</div>
    <br>
    <div class="content">・どちらの医療機関の処方せんも調剤致します</div>
    <div class="content">・無料漢方相談だけでも結構です</div>
    <div class="content">・お薬はご自宅への郵送も可能です(有料)</div>


    <!-- <div class="content">あなたの街に新しい調剤薬局ができました。</div>
    <div class="content">親切　丁寧　親身にお医者様から出されたお薬を調剤いたします。新薬はもちろん漢方薬も保険で気軽に出してもらえます</div>
    <div class="content">さらにオンラインTV電話でドクターと直接話せて診断してもらえてお薬も出してくれる病院もご紹介できます。</div>
    <div class="content">そのほかいつでも、無料にてお薬の相談、健康相談、漢方薬の相談もさせていただいてます。</div>
    <div class="content">ぜひお気軽にお立ち寄りください。</div> -->
  </div>
 
</div>
</template>

<script>
import moment from "moment";

export default {
  name: "MInfo",
  data() {
    return {
      infoList: [{
        
      }],
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getInfo(id) {
      //就用这个方法吧，现成的。缺点是多写个文件。
      this.$router.push({
        path: '/infoitem',
        query: {id}
      });
    }
  },
  created() {
    // axios.get(`${this.GLOBAL.host}/t/info/all`).then(resp => {
    //   resp.data.sort((a, b) => {
    //     let t1 = new Date(a.createTime)
    //     let t2 = new Date(b.createTime)
    //     return t2.getTime() - t1.getTime()
    //   })
    //   //取前五个
    //   resp.data = resp.data.slice(0, 5)
    //   resp.data.map(i => {
    //     i.createTime = moment(i.createTime).format('YYYY.MM.DD')
    //   })
    //   this.infoList = resp.data
    // })
  }
}
</script>

<style scoped>
#info {
  margin: 1rem;
}
#info>div {
  margin: 12px 0
}
.info-title {
  font-size: 14px;
  color: #333;
  font-weight: bold;

  width: auto;
}
.info-list {
  background-color: #ffffff;
  color: #3232e9;
  width: 100%;
  /* height: 200px; */
  margin: 0 auto 3rem;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
}
.info-list /deep/ .title  {
  color: #1796c4;
  font-size: 18px;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow : hidden;
  text-overflow: ellipsis;
}
.info-list /deep/ .time {
  margin: 1rem 0;
  font-size: 14px;
}
.info-list /deep/ .content {
  line-height: 1.4rem;
  font-size: 14px;
  text-align: left;
  /* text-indent: 2em; */
  padding-left: 7px;
  color: #3232e9;
}

.to-info {
  text-align: right;
  font-size: 12px;
}
.to-info /deep/ span {
  color: #333;
}
</style>
