<template>
  <div class="access">

    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <el-row>
          <el-col :span="12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3241.451346247618!2d139.773819!3d35.665887!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889621c1d43c3%3A0x1c2ca4a0d12f95fb!2z44CSMTA0LTAwNDUgVG9reW8sIENodW8gQ2l0eSwgVHN1a2lqaSwgNy1jaMWNbWXiiJI24oiSNyDmnb7nlLDjg5Pjg6s!5e0!3m2!1sen!2sjp!4v1715915234808!5m2!1sen!2sjp" width="100%" height="480" style="border:none;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </el-col>
          <el-col :span="11">
            <div class="schedule">
              <div class="name">
                <!-- <div class="name-tenyukai">医療法人社団 天裕会</div> -->
                <div class="name-nozomi">築地薬局</div>
              </div>
              <div class="address">
                <div>〒104-0045</div>
                <div>東京都中央区築地7丁目‐6‐7 松田ビル 2F</div>
                <br>
                <div>日比谷線「築地駅」1番出口より徒步4分</div>
              </div>
              <div class="tel">
                <div class="tel-number">TEL:</div>
                <div class="tel-number">03-5708-5852</div>
                
              </div>
              <div class="tel">
                <div class="tel-number">FAX:</div>
                <div class="tel-number">03-5708-5853</div>
                
              </div>
              <div class="table">
                <table>
                  <tr>
                    <td>営業時間</td>
                    <td>月</td>
                    <td>火</td>
                    <td>水</td>
                    <td>木</td>
                    <td>金</td>
                    <td>土</td>
                    <td>日</td>
                  </tr>
                  <tr>
                    <td>10:00 ～ 13:30</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>/</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>/</td>
                  </tr>
                  <tr>
                    <td>14:30 ～ 19:00</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>/</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>◯</td>
                    <td>/</td>
                  </tr>
                </table>
              </div>
              <!-- <div class="notice">※ 受付は３０分前までとなります。</div> -->

              <div class="info">
                <!-- <div class="btn-online" @click="goTo('https://jashop.rapha99.com/')">
                  <div><img src="../../assets/icon/yj_blue.png" alt="" width="36px" height="36px"></div>
                  <div class="btn-text">
                    <div>オンライン薬局</div>
                  </div>
                </div> -->
                <!-- <div class="btn-mail" @click="">
                  <div><img src="../../assets/icon/line.png" alt="" width="36px" height="36px"></div>
                  <div class="btn-text">
                    <div>line</div>
                  </div>
                </div> -->
                <!-- <el-popover
                  placement="right"
                  width="300"
                  trigger="click">
                  <div class="line">
                    <p>スマートフォンでご覧ください</p>
                    <img src="../../assets/carousel/ewm.jpg" height="270px" alt="">
                    <p>LINEアプリを起動して、</p>
                    <p>QRコードを読み取ってください。</p>
                  </div>
                </el-popover> -->
               
              </div>
            </div>
          </el-col>
        </el-row>

      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "HomeAccess",
  methods: {
    goTo(url) {
      window.open(url,'_blank')
    }
  }
}
</script>

<style scoped>
/*病院情報*/
.access {
  margin: 60px 0;
}
.schedule {
  width: 100%;
  margin-left: 30px;
}
.schedule .name-tenyukai {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: bold;
}
.schedule .name-nozomi {
  color: #3232e9;
  font-size: 20px;
  font-weight: bold;
}
.schedule .address {
  color: #3232e9;
  font-size: 14px;
  margin-top: 14px;
}
.schedule .tel {
  align-items: center;
  display: flex;
  margin-top: 14px;
}
.schedule .tel .tel-number {
  color: #3232e9;
  font-size: 24px;
  font-weight: bold;
  margin-left: 14px;
}
/*診療時間*/
.schedule .table table {
  color: #3232e9;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
  width: 100%;
}
.schedule .table table tr:first-child {
  background-color: #fafd3e;
}

.schedule .table table tr{
  background-color: #caeec8;
}
.schedule .table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 16px;
}
.schedule .notice {
  font-size: 12px;
  margin-top: 12px;
  margin-left: 12px;
}
/*予約・お問い合わせ*/
.info {
  margin-top: 30px;
  display: flex;
}
.info .btn-online {
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
}
.info .btn-online:hover {
  opacity: 0.8;
}
.info .btn-mail {
  min-width: 200px;
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #2ac267;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  margin-left: 20px;
}
.info .btn-mail:hover {
  opacity: 0.8;
}
.info .btn-text {
  font-size: 16px;
  margin-left: 10px;
}
.info .btn-text .sub-text {
  font-size: 12px;
  margin-top: 4px;
}
.line p{
  font-size: 14px;
  text-align: center;
}
.line img{
  margin-left: 15px
}
</style>
