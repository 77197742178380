<template>
  <div id="index" class="background">

    <div class="hidden-sm-and-down">
      <!-- <PageHeader clinic-name="築地" path-name="dougendou"/> -->

      <div class="carousel">
        <el-carousel indicator-position="inside"
                      :height="carouselHeight+'px'"
                      trigger="click"
                      :interval="8000">
          <el-carousel-item v-for="item in picNameList" :key="item">
            <el-image id="carouselImage" :src="require(`@/assets/carousel/${item}`)"/>
          </el-carousel-item>
        </el-carousel>
        <div class="home-online">
          <HomeOnline/>
        </div>
      
      </div>

      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">

          <div class="info-blog">
            <el-row>
              <el-col :span="24">
                <HomeInformation/>
              </el-col>
            </el-row>
          </div>

        </el-col>
      </el-row>

      <HomeAccess/>

      <PageFooter :showList="false"/>

    </div>

    <div class="hidden-md-and-up">
      <div class="title">築地薬局</div>
      <m-swiper/>
      <m-online/>
      <m-info/>
      <m-access/>

      <m-footer/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "../components/PageHeader";
import PageFooter from "@/components/PageFooter";
import HomeAccess from "@/components/home/HomeAccess";
import HomeInformation from "@/components/home/HomeInformation";
import MSwiper from "../components/m/MSwiper";
import MInfo from "../components/m/MInfo";
import MAccess from "../components/m/MAccess";
import MFooter from "../components/m/MFooter";
// import HomeTopics from "@/components/home/HomeTopics";
import HomeOnline from "../components/home/HomeOnline";
import MOnline from "../components/m/MOnline";

export default {
  name: "Index",
  data() {
    return {
      picNameList: [
        'img_a_main1aa.jpg',
        'img_a_main2aa.jpg',
        'img_a_main3aa.jpg',
      ],
      carouselHeight: ''
    }
  },
  components: {
    MFooter,
    MAccess,
    MInfo,
    MSwiper,
    // HomeTopics,
    HomeOnline,
    MOnline,
    HomeInformation,
    HomeAccess,
    PageFooter,
    PageHeader
  },
  methods: {
    goDepartment(name) {
      this.$router.push(`/department/${name}`)
    },
    imgHeight() {
      let pageWidth = document.documentElement.clientWidth
      // console.log('浏览器页面宽度：'+ pageWidth)

      let factor = (1920 - pageWidth) / 1920
      // console.log(`因子为：${factor}`)
      this.carouselHeight = pageWidth * 700 / 1920 + 200 * factor
    }
  },
  mounted() {
    //页面宽度变时，carousel高度也跟着变
    window.onresize = () => {
      this.imgHeight()
    }

    // twitter 详细栏
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
  },
  beforeMount() {
    //刚加载页面时，carousel高度。
    this.imgHeight()
  }
}
</script>

<style scoped>
.carousel {
  position: relative;
  margin-top: 10px;
  opacity: 0.8;
}
.home-online {
  position: absolute;
  bottom: 15%;
  right: 4%;
  z-index: 100;
}
.home-departments {
  background-color: #FFFDF7;
}
.info-blog {
  margin-top: 70px;
}
.carousel /deep/.el-carousel .el-carousel__container{
  /* width: 62% !important; */
  /* margin: auto; */
}

.background{
  background-color:#ffffff ;
}

.title{
  color: #3232e9;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
  font-size: 55px;
  font-weight: bold;
  height:auto;
  background-color: #ffff99;
}
</style>
