<template>
<div id="online" class="online">
  <div class="info">
    <div class="info-title">営業時間</div>
    <div class="info-content">
      <div>
        <span class="content-title">営業時間</span>
        <span class="content-text">10:00 ~ 13:30</span>
        <span class="content-text">14:30 ~ 19:00</span>
      </div>
      <div>
        <span class="content-title">定休日　</span>
        <span class="content-text">日曜日・水曜日・祝日</span>
      </div>
    </div>
    <div class="info-tel">
      <div class="tel-number">TEL:</div>
      <div class="tel-number">03-5708-5852</div>
    </div>
  </div>
  <!--<div class="btn" @click="goTo('telemedicine')">
    <div>
      <img src="../../assets/icon/yj_blue.png" alt="" width="36px" height="36px">
    </div>
    <div class="text">
      <div class="text-online">オンライン薬局</div>
    </div>
  </div>-->
</div>
</template>

<script>
export default {
  name: "MOnline",
  methods: {
    goTo(page) {
      this.$router.push(`/department/${page}`)
    }
  }
}
</script>

<style scoped>
.online {
  margin: 30px 20px;
}
/* 病院情報 */
.info .info-title {
  color: #3232e9;
  font-size: 16px;
  font-weight: bold;
}
.info .info-content {
  margin-top: 14px;
  line-height: 1.5;
}
.info .content-title {
  color: #3232e9;
  font-size: 12px;
  font-weight: bold;
}
.info .content-text {
  font-size: 12px;
  color: #3232e9;
  margin-left: 20px;
}
.info .info-tel {
  align-items: center;
  display: flex;
  margin-top: 20px;
}
.info .tel-number {
  font-size: 24px;
  font-weight: bold;
  color: #3232e9;
  margin-left: 14px;
}
/* オンライン予約 */
.btn {
  align-items: center;
  background-color: #FFFFFF;
  border: 2px solid #3232e9;
  border-radius: 5px;
  box-shadow:  2px 0 4px #cccccc;
  cursor: pointer;
  display: flex;
  margin-top: 20px;
  padding: 8px 16px;
}
.btn:hover {
  background-color: #40A3D8;
}
.text {
  margin-left: 10px;
}
.text-web {
  font-weight: bold;
  font-size: 16px;
  color: #62B6E2;
}
.text-online {
  font-weight: bold;
  font-size: 16px;
  color: #3232e9;
}
</style>
